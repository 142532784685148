<template>
  <div class="container-sequence">
    <HeaderCampanha
      :all_funnel="all_funnel"
      :current_funnel="current_funnel"
      :campaign="campaign"
      @moveCardDrag="moveCardDrag"
    />
    <div v-show="!loading">
      <div
        id="canvas"
        ref="background"
        :style="'transform:' + matrixz"
        @mouseup="handleDropCanvas($event)"
      >
        <div
          v-if="skeleton_show"
          :style="skeletonStyle"
          class="container-skeleton"
        >
          <!-- Skeleton Card  -->
          <b-skeleton
            style="margin: 0 !important"
            width="100%"
            height="100%"
          ></b-skeleton>
          <!-- Skeleton Email icon  -->
          <b-skeleton
            style="
              margin: 0 !important;
              position: absolute;
              top: 20px;
              left: 20px;
            "
            width="40px"
            height="40px"
          ></b-skeleton>
          <!-- Skeleton Email env  -->
          <b-skeleton
            style="
              margin: 0 !important;
              position: absolute;
              top: 30px;
              left: 75px;
            "
            width="150px"
            height="20px"
          ></b-skeleton>
          <!-- Skeleton Email options  -->
          <b-skeleton
            style="
              margin: 0 !important;
              position: absolute;
              top: 33px;
              left: 240px;
            "
            width="40px"
            height="15px"
          ></b-skeleton>
          <!-- Skeleton Email type  -->
          <b-skeleton
            style="
              margin: 0 !important;
              position: absolute;
              top: 80px;
              left: 20px;
            "
            width="20px"
            height="20px"
          ></b-skeleton>
          <!-- Skeleton Email name  -->
          <b-skeleton
            style="
              margin: 0 !important;
              position: absolute;
              top: 80px;
              left: 50px;
            "
            width="220px"
            height="20px"
          ></b-skeleton>
          <!-- Skeleton Email icon type  -->
          <b-skeleton
            style="
              margin: 0 !important;
              position: absolute;
              top: 112px;
              left: 20px;
            "
            width="20px"
            height="20px"
          ></b-skeleton>
          <!-- Skeleton Email name  -->
          <b-skeleton
            style="
              margin: 0 !important;
              position: absolute;
              top: 112px;
              left: 50px;
            "
            width="220px"
            height="20px"
          ></b-skeleton>
          <!-- Skeleton Email icon time  -->
          <b-skeleton
            style="
              margin: 0 !important;
              position: absolute;
              top: 142px;
              left: 20px;
            "
            width="20px"
            height="20px"
          ></b-skeleton>
          <!-- Skeleton Email time  -->
          <b-skeleton
            style="
              margin: 0 !important;
              position: absolute;
              top: 142px;
              left: 50px;
            "
            width="115px"
            height="20px"
          ></b-skeleton>
          <!-- Skeleton Email dado  -->
          <b-skeleton
            style="
              margin: 0 !important;
              position: absolute;
              top: 172px;
              left: 20px;
            "
            width="145px"
            height="20px"
          ></b-skeleton>
          <!-- Skeleton Email open  -->
          <b-skeleton
            style="
              margin: 0 !important;
              position: absolute;
              top: 202px;
              left: 20px;
            "
            width="130px"
            height="20px"
          ></b-skeleton>
          <!-- Skeleton Email click  -->
          <b-skeleton
            style="
              margin: 0 !important;
              position: absolute;
              top: 225px;
              left: 20px;
            "
            width="145px"
            height="20px"
          ></b-skeleton>
          <!-- Skeleton Email status  -->
          <b-skeleton
            style="
              margin: 0 !important;
              position: absolute;
              top: 213px;
              left: 240px;
            "
            width="40px"
            height="25px"
          ></b-skeleton>
        </div>
        <div
          v-for="(card, index) in cards"
          :key="card.id"
          class="card validDrag"
          :style="cardStyle(card)"
          @mousedown="
            startDragging(card, $event, index, 'item'), (selectedCard = card)
          "
          @mouseup="handleDrop(card, $event, index), (selectedCard = null)"
          :ref="`card${index}`"
          :data-cardId="card.id"
        >
          <div v-if="index === 0" class="initial-card validDrag">
            <h1 class="validDrag">Ponto de partida</h1>
          </div>
          <div class="header-card validDrag">
            <img
              style="pointer-events: none"
              class="validDrag"
              src="@/assets/img/icons/campanhas/init.svg"
              alt="init"
              v-if="index === 0"
            />
            <img
              class="validDrag"
              style="pointer-events: none"
              :class="{
                'open-not-click': card.send_to === 'open_not_click',
                'not-open': card.send_to === 'not_open',
                'click-objective': card.send_to === 'click_objective',
              }"
              src="@/assets/img/icons/campanhas/init-emails.svg"
              alt="init"
              v-else-if="card.send_to != 'tag_specific'"
            />
            <img
              class="validDrag"
              style="pointer-events: none"
              src="@/assets/img/icons/campanhas/init-emails2.svg"
              alt="init"
              v-else-if="card.send_to == 'tag_specific'"
            />

            <div class="text validDrag">
              <h1
                v-if="card.text"
                class="validDrag"
                :style="{
                  width:
                    card && card.text && card.text.length < 14
                      ? '100% !important'
                      : '90% !important;',
                }"
              >
                {{ card.text }}
              </h1>
              <h1 v-else class="validDrag">Enviar e-mail</h1>
              <img
                id="image"
                @click="card.dropDown = true"
                class="validDrag notRequisition"
                src="@/assets/img/icons/campanhas/points.svg"
                alt="points"
                v-if="index !== 0"
              />
              <div
                @mouseleave="card.dropDown = false"
                v-show="card.dropDown"
                class="dropDownCard notRequisition"
              >
                <div
                  v-if="card.id != 0"
                  v-b-toggle.sidebar-ActionStepsCampanha
                  @click="cardSelected = card"
                  class="actions notRequisition validDrag"
                >
                  <img
                    style="pointer-events: none"
                    src="@/assets/img/icons/campanhas/eye.svg"
                    alt="visualize notRequisition"
                  />

                  <p class="notRequisition">Visualizar detalhes</p>
                </div>
                <div
                  class="actions notRequisition validDrag"
                  @click="openModal('CampanhaModalEmail', card, 'create_email')"
                >
                  <img
                    style="pointer-events: none"
                    class="notRequisition validDrag"
                    src="@/assets/img/icons/campanhas/add.svg"
                    alt="add"
                  />
                  <p class="notRequisition">Novo e-mail</p>
                </div>
                <div
                  v-if="card.id != 0"
                  class="actions notRequisition validDrag"
                  @click="openModal('CampanhaModalEmail', card, 'edit_email')"
                >
                  <img
                    style="pointer-events: none"
                    class="notRequisition validDrag"
                    src="@/assets/img/icons/campanhas/edit.svg"
                    alt="edit"
                  />
                  <p class="notRequisition">Editar e-mail</p>
                </div>
                <div
                  v-if="card.id != 0"
                  class="actions notRequisition validDrag"
                  @click="
                    openModal('CampanhaModalQualificacao', card, 'edit_email')
                  "
                >
                  <img
                    style="pointer-events: none"
                    class="notRequisition validDrag"
                    src="@/assets/img/icons/campanhas/automation.svg"
                    alt="automation"
                  />
                  <p class="notRequisition">Funil de e-mails</p>
                </div>
                <div
                  v-if="card.id != 0"
                  class="actions trash notRequisition"
                  @click="removeCard(card, index)"
                >
                  <img
                    style="pointer-events: none"
                    class="notRequisition"
                    src="@/assets/img/icons/campanhas/trash.svg"
                    alt="trash"
                  />
                  <p class="notRequisition" style="color: #f10d43">
                    Remover e-mail
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="content-card validDrag">
            <!-- 1 CARD -->
            <div class="title validDrag">
              <img
                style="pointer-events: none"
                class="validDrag"
                src="@/assets/img/icons/campanhas/init-email.svg"
                alt="arrow"
                v-if="index === 0"
              />
              <img
                style="pointer-events: none"
                class="validDrag"
                src="@/assets/img/icons/campanhas/env-email.svg"
                alt="arrow"
                v-else
              />
              <h2 v-if="index === 0" class="validDrag">Inicio do funil</h2>
              <h2
                v-else-if="filterEmailName(card.reference_id).length > 23"
                class="validDrag"
                v-b-tooltip.hover.right
                :title="filterEmailName(card.reference_id)"
              >
                {{ filterEmailName(card.reference_id) }}
              </h2>
              <h2 v-else class="validDrag">
                {{ filterEmailName(card.reference_id) }}
              </h2>
            </div>
            <!-- CARDS -->
            <div class="title validDrag" v-if="index != 0">
              <img
                style="pointer-events: none"
                class="validDrag"
                src="@/assets/img/icons/campanhas/card-type.people.svg"
                alt="arrow"
                v-if="card.send_to === 'all_leads'"
              />
              <img
                style="pointer-events: none"
                class="validDrag"
                src="@/assets/img/icons/campanhas/card-type-open-not-click.svg"
                alt="arrow"
                v-else-if="card.send_to === 'open_not_click'"
              />
              <img
                style="pointer-events: none"
                class="validDrag"
                src="@/assets/img/icons/campanhas/card-type-not-open.svg"
                alt="arrow"
                v-else-if="card.send_to === 'not_open'"
              />
              <img
                style="pointer-events: none"
                class="validDrag"
                src="@/assets/img/icons/campanhas/card-type-click-objective.svg"
                alt="arrow"
                v-else-if="card.send_to === 'click_objective'"
              />
              <img
                style="pointer-events: none"
                class="validDrag"
                src="@/assets/img/icons/campanhas/card-type-tag-specific.svg"
                alt="arrow"
                v-else-if="card.send_to === 'tag_specific'"
              />
              <h2 class="validDrag">{{ filterSendType(card.send_to) }}</h2>
            </div>
            <div class="title validDrag" v-if="index != 0">
              <img
                style="pointer-events: none"
                class="validDrag"
                src="@/assets/img/icons/campanhas/type-time.svg"
                alt="arrow"
              />
              <h2 class="validDrag">{{ filterTimeCard(card.send_at) }}</h2>
            </div>

            <p class="validDrag" v-if="index === 0">
              Quem deve participar desse funil
            </p>

            <p class="validDrag" v-else>Dados da entrega</p>
            <div v-if="index === 0">
              <span class="validDrag"
                >Todos que se cadastraram no formulário</span
              >
              <div class="card-formulario">
                <BaseSelect
                  id="card-formulario"
                  :selectModel="selected_form"
                  placeholder="Selecione um formulário"
                  track-by="id"
                  trackname="title"
                  :array="all_forms"
                  @select="updateForm"
                  class="select-camp"
                  selectLabel=""
                  deselectLabel=""
                  :loading="loadingForm"
                  @search="debounceForms($event)"
                  noResult="Nenhum formulário encontrado."
                  @callback="fetchFunnelAll2($event)"
                  @change="selected_form = $event"
                  :watch="true"
                  :selectEvent="true"
                >
                </BaseSelect>
              </div>
            </div>
            <div class="validDrag" style="display: grid" v-else>
              <span>{{ card.email_delivereds > 1 || card.email_delivereds == 0 ? `${card.email_delivereds} e-mails entregues` : `${card.email_delivereds} e-mail entregue` }}</span>
              <span>{{ card.email_opens > 1 || card.email_opens == 0 ? `${card.email_opens} e-mails abertos` : `${card.email_opens} e-mail aberto` }}</span>
              <span>{{ card.email_clicks > 1 || card.email_clicks == 0 ? `${card.email_clicks} e-mails clicados` : `${card.email_clicks} e-mail clicado` }}</span>
            </div>

            <span
              class="validDrag"
              style="position: absolute; right: 12px; bottom: 30px"
              v-b-tooltip.hover
              :title="card.status ? 'Desativar e-mail' : 'Ativar e-mail'"
            >
              <b-form-checkbox
                class="validDrag"
                v-if="index != 0"
                v-model="card.status"
                name="is_main"
                size="lg"
                @change="eventSwitch(card, index)"
                switch
              >
              </b-form-checkbox>
            </span>
          </div>
          <div
            class="container-button validDrag"
            @click="openModal('CampanhaModalEmail', card, 'create_email')"
          >
            <button class="validDrag">
              <img
                style="pointer-events: none"
                src="@/assets/img/icons/campanhas/add-card.svg"
                alt="add"
              />
            </button>
          </div>
        </div>
        <!-- Div para renderizar as linhas -->
        <canvas
          id="renderLine"
          ref="canvas"
          class="validDrag"
          width="15000"
          height="5000"
        ></canvas>
        <div class="lines-container">
          <div
            v-for="(line, index2) in lines"
            :key="`line-horizontal-${index2}`"
            class="line"
            :style="lineStyle(line, index2)"
          >
            <button
              v-if="lines[index2].card2.parent !== 0"
              class="addCardButton"
              :data-buttonIndex="index2"
              :style="lineStyle(line, index2, 'button')"
              @click="
                openModal('CampanhaModalEmail', line, 'create_email_button')
              "
            >
              <img src="@/assets/img/icons/campanhas/add-card.svg" alt="add" />
            </button>
          </div>
        </div>
        <!-- Div Notas -->
        <div
          v-for="(nota, index) in notes"
          :key="nota.id"
          class="card validDrag"
          :style="noteStyle(nota)"
          style="width: 250px; padding: 15px"
          @mousedown="startDragging(nota, $event, index, 'note')"
          @mouseup="handleDrop2(nota, $event, index)"
        >
          <div class="validDrag">
            <div class="title-note validDrag">
              <label
                @click="
                  (nota.edditable = true), focusNote(`note-title-${index}`)
                "
                v-if="!nota.edditable"
                style="user-select: none"
                class="validDrag"
                >{{ nota.title }}</label
              >
              <input
                style="user-select: none"
                id="note-edit-title"
                v-else
                type="text"
                v-model="nota.title"
                :ref="`note-title-${index}`"
                @blur="(nota.edditable = false), saveNote(nota)"
                class="validDrag"
              />
              <img
                @click="deleteNote(nota)"
                src="@/assets/img/icons/campanhas/trash.svg"
                alt="trash"
                class="validDrag"
              />
            </div>
            <div style="position: relative" class="validDrag">
              <div
                v-if="!nota.edditable"
                @click="
                  (nota.edditable = true), focusNote(`note-content-${index}`)
                "
                class="faketextarea text-area validDrag notRequisition disabledTextArea"
              >
                {{ nota.description }}
              </div>
              <textarea
                v-else
                style="user-select: none"
                class="text-area validDrag notRequisition editabbleTextArea"
                v-model="nota.description"
                :disabled="!nota.edditable"
                :ref="`note-content-${index}`"
                @blur="(nota.edditable = false), saveNote(nota)"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <!-- Div Zoom -->
      <div class="content-zoom">
        <div class="zoom2" v-if="show_zoom">{{ zoom }}x</div>
        <div class="zoom" @click="scaleCanvas('add')">
          <img
            src="@/assets/img/icons/campanhas/zoom-plus.svg"
            alt="add"
            class="validDrag"
          />
        </div>
        <div class="zoom" @click="scaleCanvas('minus')">
          <img
            src="@/assets/img/icons/campanhas/zoom-minus.svg"
            alt="minus"
            class="validDrag"
          />
        </div>
      </div>
    </div>
    <div class="container-loading" v-if="loading">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <CampanhasSideBarAcoes :cardSelected="cardSelected" :emails="emails" />
    <CampanhaModalEmail
      @dadosEmail="dadosEmail"
      :cards="cards"
      :type_api_email="type_api_email"
      :cardSelected="cardSelected"
      :emailSelected="emailSelected"
      :destinatario="destinatario"
    />
    <CampanhasModalQualificacao
      @dadosQualification="dadosQualification"
      :type_api_email="type_api_email"
      :cardSelected="cardSelected"
      :qualification="qualification"
      :automations="automations"
    />
    <CampanhasListLeads
      :cardSelected="cardSelected"
      :emailSelected="emailSelected"
    />
  </div>
</template>

<script>
//
import HeaderCampanha from "./HeaderCampanha";
//
import CampanhasSideBarAcoes from "./CampanhasSideBarAcoes.vue";
import CampanhaModalEmail from "./CampanhasModalEmail.vue";
import CampanhasModalQualificacao from "./CampanhasModalQualificacao.vue";
import CampanhasListLeads from "./CampanhasListLeads.vue";
//
import Vue from "vue";
//
import FunnelService from "@/services/resources/FunnelService";
const serviceFunnel = FunnelService.build();
//
import CampaignService from "@/services/resources/CampaignService";
const serviceCampaign = CampaignService.build();
//
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();
//
import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();
//
import _ from "lodash";
//
export default {
  components: {
    HeaderCampanha,
    CampanhasSideBarAcoes,
    CampanhaModalEmail,
    CampanhasModalQualificacao,
    CampanhasListLeads,
  },
  data() {
    return {
      searchForm: "",
      stopForm: false,
      loadingForm: false,
      pageForm: 1,
      stop: false,
      destinatario: [],
      skeleton_show: false,
      skeleton_body: { x: 0, y: 0 },
      zoomTimeout: null,
      show_zoom: false,
      selected_form: {},
      all_forms: [],
      all_funnel: [],
      campaign: {},
      campanhas: [],
      current_funnel: {},
      type_api_email: "",
      funnel_id: null,
      cardSelected: null,
      emailSelected: null,
      matrixz: null,
      zoom: 10,
      isDragging: false,
      startX: 0,
      startY: 0,
      card_initial: {
        id: 0,
        text: "Novos Leads",
        reference_id: "0",
        reference_type: "email",
        x: 100,
        y: 100,
        isDragging: false,
        offsetX: 0,
        offsetY: 0,
        parent: null,
        dropDown: false,
        type: "inicio",
      },
      cards: [],
      last_card: null,
      inside_card: false,
      mouseX: 0,
      mouseY: 0,
      isMouseDown: false,
      selectedCard: null,
      lines: [],
      //
      controlMoveCard: true,
      emails: [],
      graphEmail: [],
      qualification: {},
      // NOTES
      notes: [],
      loading: false,
      automations: [],
    };
  },
  watch: {
    $route() {
      if (!this.loading) {
        this.clearAllLines();
      }
    },
  },
  computed: {
    skeletonStyle() {
      var skeleton = this.skeleton_body;
      return {
        top: `${skeleton.y}px`,
        left: `${skeleton.x}px`,
      };
    },
  },
  methods: {
    updateForm() {
      var data = {
        id: this.$route.params.funnel_id,
        form_id: this.selected_form.id,
        title: this.selected_form.title,
      };
      serviceFunnel
        .update(data)
        .then((resp) => {
          this.$bvToast.toast("Formulário atualizado!", {
            title: "Sequência",
            variant: "info",
            autoHideDelay: 8000,
            appendToast: true,
          });
        })
        .catch((error) => {
          this.$bvToast.toast("Erro ao atualizar formulário!", {
            title: "Sequência",
            variant: "danger",
            autoHideDelay: 8000,
            appendToast: true,
          });
        })
        .finally(() => {});
    },
    // DELETA A NOTA --->>>
    deleteNote(nota) {
      Vue.swal({
        title: "Nota",
        text: `Deseja realmente remover a nota ?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {
            id: `/note/${nota.id}`,
          };
          serviceCampaign.destroy(data).then((resp) => {
            var indexNote = this.notes.findIndex(
              (notaFind) => notaFind.id === nota.id
            );
            this.notes.splice(indexNote, 1);
            this.$bvToast.toast("Nota removida!", {
              title: "Sequência",
              variant: "info",
              autoHideDelay: 8000,
              appendToast: true,
            });
          });
        }
      });
    },
    // SALVAR A NOTA --->>>
    saveNote(nota) {
      var data = {
        id: `/note/${nota.id}`,
        title: nota.title,
        description: nota.description,
      };
      serviceCampaign.update(data);
      nota.edditable = false;
      this.$bvToast.toast("Nota atualizada!", {
        title: "Sequência",
        variant: "info",
        autoHideDelay: 8000,
        appendToast: true,
      });
    },
    //DAR FOCUS NOS INPUTS DA NOTA
    focusNote(focusName) {
      this.$nextTick(() => this.$refs[focusName][0].focus());
    },
    // PARA SALVAR A POSIÇÃO QUANDO MOVER --->>>
    debouncePosition: _.debounce(function (position) {
      var funnel_data = {
        id: this.$route.params.funnel_id,
        metas: {
          window_x: position.x,
          window_y: position.y,
        },
      };
      serviceFunnel.update(funnel_data);
    }, 1000),
    // FUNÇÃO PARA MOVER A NOTA COM O MOUSE --->>>
    handleMouseUp2() {
      this.notes.forEach((nota) => {
        nota.isDragging = false;
      });
    },
    // FUNÇÃO PARA MOVER A NOTA COM O MOUSE --->>>
    startDragging2(nota, event, index) {
      if (nota.edditable) {
        return;
      }
      if (!this.controlMoveCard) {
        return;
      }
      nota.isDragging = true;
      this.isMouseDown = true;
      nota.offsetX = event.clientX - nota.x;
      nota.offsetY = event.clientY - nota.y;
    },
    // SALVA POSICÇÂO DAS NOTAS QUANDO LEVANTA O MOUSE -->>
    handleDrop2(nota, event, index) {
      if (
        nota.isDragging == false ||
        event.target.classList.contains("notRequisition") ||
        nota.id === 0
      ) {
        return;
      }

      nota = this.notes[index];

      var data = {
        id: "/note/" + this.notes[index].id,
        title: nota.title,
        description: nota.description,
        position: [
          {
            x: nota.x,
            y: nota.y,
            offsetX: 0,
            offsetY: 0,
            isDragging: false,
            dropDown: false,
          },
        ],
      };
      serviceCampaign.update(data);
    },
    // ATUALIZA A POSIÇÂO DAS NOTAS QUANDO MOVE O MOUSE -->>
    updateNotePositions() {
      this.notes.forEach((note) => {
        if (note.isDragging) {
          note.x = this.mouseX - note.offsetX;
          note.y = this.mouseY - note.offsetY;
        }
      });
    },
    // CALCULA A POSIÇÃO DA NOTA --->>>
    noteStyle(note) {
      return {
        top: `${note.y}px`,
        left: `${note.x}px`,
      };
    },
    // CRIA AS NOTAS --->>>
    createNote() {
      var data = {
        id: `/note`,
        campaign_id: this.$route.params.funnel_id,
        title: "Nova nota",
        position: [
          {
            x: 1530,
            y: 5,
            offsetX: 0,
            offsetY: 0,
            isDragging: false,
            edditable: false,
          },
        ],
        description: "Edite aqui sua nota...",
        status: "active",
        type: "note",
      };
      serviceCampaign
        .createId(data)
        .then((resp) => {
          var new_nota = {
            id: resp.id,
            campaign_id: this.$route.params.funnel_id,
            title: data.title,
            x: data.position[0].x,
            y: data.position[0].y,
            offsetX: data.position[0].offsetX,
            offsetY: data.position[0].offsetY,
            isDragging: data.position[0].isDragging,
            edditable: data.position[0].edditable,
            description: data.description,
            status: data.status,
          };
          this.notes.push(new_nota);
          this.$bvToast.toast("Nota adicionada!", {
            title: "Sequência",
            variant: "info",
            autoHideDelay: 8000,
            appendToast: true,
          });
        })
        .catch((error) => {})
        .finally(() => {});
    },
    // BUSCA AS NOTAS --->>>
    getNotes() {
      serviceCampaign
        .read("/note/list/" + this.$route.params.funnel_id)
        .then((resp) => {
          var all_notes = [];
          for (let i = 0; i < resp.length; i++) {
            const nota = resp[i];
            var get_note = {
              id: nota.id,
              campaign_id: nota.campaign_id,
              title: nota.title,
              x: nota.position[0].x,
              y: nota.position[0].y,
              offsetX: nota.position[0].offsetX,
              offsetY: nota.position[0].offsetY,
              isDragging: nota.position[0].isDragging,
              edditable: nota.position[0].edditable,
              description: nota.description,
              status: nota.status,
            };
            all_notes.push(get_note);
          }
          this.notes = all_notes;
        })
        .catch((error) => {})
        .finally(() => {});
    },
    filterTimeCard(time) {
      if (
        time === null ||
        time === "0" ||
        time === "" ||
        time === "null|null"
      ) {
        return "Envio não definido";
      }

      const [days, timeString] = time.split("|");
      const [hours, minutes] = timeString.split(":");

      if (days === "00" || days == "000") {
        return `${minutes} minuto${minutes !== "01" ? "s" : ""}`;
      }

      if (days !== "0") {
        const daysText = days !== "1" ? "dias" : "dia";
        return `${days} ${daysText}`;
      }

      return `${hours} hora${hours !== "01" ? "s" : ""} e ${minutes} minuto${
        minutes !== "01" ? "s" : ""
      }`;
    },
    dadosQualification(data_qualification, automations) {
      this.qualification = data_qualification;
      this.automations = automations;
    },
    filterEmailOpen(id) {
      var result = this.graphEmail.find((graph) => graph.id_email == id);
      if (!result || result.data === undefined) {
        return "0";
      }
      var nomeDaPropriedade = Object.keys(result.data.email_opens)[0];

      if (result.data.email_opens[nomeDaPropriedade] === undefined) {
        return "0";
      }
      return result.data.email_opens[nomeDaPropriedade];
    },
    filterEmailClick(id) {
      var result = this.graphEmail.find((graph) => graph.id_email == id);
      if (!result || result.data === undefined) {
        return "0";
      }
      var nomeDaPropriedade = Object.keys(result.data.email_clicks)[0];

      if (result.data.email_clicks[nomeDaPropriedade] === undefined) {
        return "0";
      }
      return result.data.email_clicks[nomeDaPropriedade];
    },
    getGraphEmail() {
      this.graphEmail = [];
      // var all_cards = [...this.cards.shift()];
      for (let i = 0; i < this.cards.length; i++) {
        const card = this.cards[i];
        if (card.reference_id != 0) {
          serviceFunnel
            .read(`/email/get/graph/${card.reference_id}`)
            .then((resp) => {
              // resp.data.id_email = card.reference_id;
              var graph = {
                id_email: card.reference_id,
                data: resp.data,
              };
              this.graphEmail.push(graph);
            })
            .catch((err) => {})
            .finally(() => {});
        }
      }
    },
    filterSendType(type) {
      if (!type) {
        return "Enviar para todos os leads";
      }
      const all_types = {
        all_leads: "Enviar para todos os leads",
        open_not_click: "Abriram, mas não clicaram",
        not_open: "Nem abriram o e-mail",
        click_objective: "Clicaram no objetivo",
        tag_specific: "Enviar para Tag especifica",
      };
      return all_types[type];
    },
    filterEmailName(id) {
      const emails = this.emails.find((email) => email.id == id);
      return emails ? emails.title : "Nenhum email selecionado";
    },
    getEmails() {
      serviceFunnel
        .read(`/email/list/${this.funnel_id}`)
        .then((resp) => {
          setTimeout(() => {
            this.emails = resp;
          }, 2000);
        })
        .catch((err) => {});
    },
    dadosEmail(cardSelected, dadosEmail, dataProps) {
      var id_email = this.emails.find(
        (email) => email.id == cardSelected.reference_id
      );
      var data = {
        id: `/email/update/${id_email.id}`,
        from_id: dadosEmail.from_id,
        html: dadosEmail.html,
        json: dadosEmail.json,
        title: dadosEmail.title,
      };
      serviceFunnel.update(data);
      if (dadosEmail.tag_dispatch) {
        this.qualification.tag_dispatch = dadosEmail.tag_dispatch;
        var att_send_filter = {
          id: `/items/${cardSelected.id}`,
          send_filter: this.qualification,
        };
        serviceFunnel.update(att_send_filter);
      }
      this.cardSelected = [];
      // Primeiro atualiza o card -->
      var index_card = this.cards.findIndex(
        (cardFind) => cardFind.id == cardSelected.id
      );
      if (index_card !== -1) {
        cardSelected.automations = dataProps.automations;
        cardSelected.send_at = dataProps.send_at;
        cardSelected.send_filter = dataProps.send_filter;
        cardSelected.send_to = dataProps.send_to;
        this.cards[index_card] = cardSelected;

        var index_email = this.emails.findIndex(
          (email) => email.id == cardSelected.reference_id
        );
        // Atualiza o E-mail -->
        if (index_email !== -1) {
          var new_all_emails = [...this.emails];
          new_all_emails[index_email].from_id = dadosEmail.from_id;
          new_all_emails[index_email].html = dadosEmail.html;
          new_all_emails[index_email].json = dadosEmail.json;
          new_all_emails[index_email].title = dadosEmail.title;
          this.emails = [];
          this.emails = new_all_emails;
        } else {
          this.loadAllFunction();
        }
      } else {
        this.loadAllFunction();
      }

      // this.loadAllFunction();
    },
    moveCardDrag(move) {
      this.controlMoveCard = move;
    },
    scaleCanvas(type) {
      if (type === "add") {
        if (this.zoom < 19) {
          this.zoom++;
          this.show_zoom = true;
        } else {
          this.$bvToast.toast("Zoom Máximo!", {
            title: "Sequência",
            variant: "info",
            autoHideDelay: 8000,
            appendToast: true,
          });
          return;
        }
      }
      if (type === "minus") {
        if (this.zoom > 2) {
          this.zoom--;
          this.show_zoom = true;
        } else {
          this.$bvToast.toast("Zoom Mínimo!", {
            title: "Sequência",
            variant: "info",
            autoHideDelay: 8000,
            appendToast: true,
          });
          return;
        }
      }

      var funnel_data = {
        id: this.$route.params.funnel_id,
        metas: {
          zoom: this.zoom,
        },
      };
      serviceFunnel.update(funnel_data);

      const zoomToMatrix = {
        19: "matrix(1.9, 0, 0, 1.9, 6750, 2250) !important;",
        18: "matrix(1.8, 0, 0, 1.8, 6000, 2000) !important;",
        17: "matrix(1.7, 0, 0, 1.7, 5250, 1750) !important;",
        16: "matrix(1.6, 0, 0, 1.6, 4500, 1500) !important;",
        15: "matrix(1.5, 0, 0, 1.5, 3750, 1250) !important;",
        14: "matrix(1.4, 0, 0, 1.4, 3000, 1000) !important;",
        13: "matrix(1.3, 0, 0, 1.3, 2250, 750) !important;",
        12: "matrix(1.2, 0, 0, 1.2, 1500, 500) !important;",
        11: "matrix(1.1, 0, 0, 1.1, 750, 250) !important;",
        10: "matrix(1, 0, 0, 1, 0, 0) !important;",
        9: "matrix(0.9, 0, 0, 0.9, -750, -250) !important;",
        8: "matrix(0.8, 0, 0, 0.8, -1500, -500) !important;",
        7: "matrix(0.7, 0, 0, 0.7, -2250, -750) !important;",
        6: "matrix(0.6, 0, 0, 0.6, -3000, -1000) !important;",
        5: "matrix(0.5, 0, 0, 0.5, -3750, -1250) !important;",
        4: "matrix(0.4, 0, 0, 0.4, -4500, -1500) !important;",
        3: "matrix(0.3, 0, 0, 0.3, -5250, -1750) !important;",
        2: "matrix(0.2, 0, 0, 0.2, -6000, -2000) !important;",
        1: "matrix(0.1, 0, 0, 0.1, -6750, -2250) !important;",
      };

      if (zoomToMatrix.hasOwnProperty(this.zoom)) {
        this.matrixz = zoomToMatrix[this.zoom];
      }

      clearTimeout(this.zoomTimeout);
      this.zoomTimeout = setTimeout(() => {
        this.show_zoom = false;
      }, 3000);
    },
    clearAllLines() {
      this.lines = [];
      this.cards = [];
      this.notes = [];
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.loadAllFunction();
    },
    lineStyle(line, index, type) {
      if (this.loading) {
        return;
      }
      const card1 = this.cards.find((card) => card.id === line.card_parent);
      const card2 = this.cards.find((card) => card.id === line.card_children);
      // Valida como vai se a LINHA -->>>
      var count_parent = [];
      for (let i = 0; i < this.cards.length; i++) {
        const card = this.cards[i].parent;
        if (line.card1.id == card) {
          count_parent.push(count_parent);
        }
      }

      var divCard = document.querySelector(`[data-index="${index}"]`)
        ? document.querySelector(`[data-index="${index}"]`).offsetHeight
        : 264;

      const cardHeight = divCard; // Defina a altura dos cards de acordo com a altura real dos seus cards
      const verticalSpacing = 0; // Espaçamento vertical entre as linhas

      const startY = card1.y + cardHeight / 2 + index * verticalSpacing;
      const endY = card2.y + cardHeight / 2 + index * verticalSpacing;

      const width = Math.abs(line.endX - line.startX);
      var height = Math.abs(endY - startY);
      var left = Math.min(line.startX, line.endX);
      var top = Math.min(startY, endY);

      var style_button = null;
      var style_button_return = null;
      if (count_parent.length === 1) {
        const zoomLevelToScale = {
          19: 1.1,
          18: 1.1,
          17: 1.1,
          16: 1.1,
          15: 1.1,
          14: 1.1,
          13: 1.1,
          12: 1.1,
          11: 1.1,
          10: 1.0,
          9: 1.0,
          8: 1.0,
          7: 1.0,
          6: 1.0,
          5: 1.0,
          4: 1.0,
          3: 1.0,
          2: 1.0,
          1: 1.0,
        };

        // Obtém a escala correspondente ao nível de zoom
        const scale = zoomLevelToScale[this.zoom] || 1;
        const transformedStartY = startY * scale;
        const transformedEndY = endY * scale;

        // Atualiza a estrutura de dados com as informações da nova linha
        this.lines[index] = {
          id: JSON.stringify(line.card1.id) + JSON.stringify(line.card2.id),
          card1: line.card1,
          card2: line.card2,
          card_parent: line.card1.id,
          card_children: line.card2.id,
          startX: line.startX,
          startY: transformedStartY,
          endX: line.endX,
          endY: transformedEndY,
        };
        // Seleciona o canvas
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext("2d");
        // Limpa o canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // Desenha todas as linhas novamente
        ctx.strokeStyle = "#D9D9D9";
        ctx.lineWidth = 5;
        ctx.lineCap = "round";

        this.lines.forEach((line, lineIndex) => {
          var count_parent_forEach = [];
          for (let i = 0; i < this.cards.length; i++) {
            const card = this.cards[i].parent;
            if (line.card_parent === card) {
              count_parent_forEach.push(count_parent_forEach);
            }
          }
          if (count_parent_forEach.length === 1) {
            // Cálculo das coordenadas do ponto médio da linha
            const midXButton = (line.startX + line.endX) / 2;
            const midYButton = (line.startY + line.endY) / 2;
            // Posicionamento do botão --->
            const buttonSize = 30;
            // Centralido ao meio da Linha ---->>
            // const buttonLeft = midXButton - buttonSize / 2;
            // const buttonTop = midYButton - buttonSize / 2;
            // SISTEMA DE COLISÃO --->>>
            var isVertical = false;
            var multiple = 0.5;
            if (line.endY > 1400 || line.endY < 900) {
              isVertical = true;
            }
            var leftCard1 = line.card1.x;
            var leftCard2 = line.card2.x;
            if (
              leftCard2 < leftCard1 &&
              Math.abs(leftCard2 - leftCard1) > 190
            ) {
              isVertical = true;
              multiple = 0.3;
            }
            if (
              leftCard2 > leftCard1 &&
              Math.abs(leftCard2 - leftCard1) > 190
            ) {
              isVertical = false;
              multiple = 0.5;
            }
            //   "XXXX dos cards -->",
            //   line.card1.x,
            //   line.card2.x,
            //   "isVertical -->",
            //   isVertical,
            //   "multiple -->",
            //   multiple
            // );
            // Centralido em 75% da Linha ou o multiplo ---->>
            var buttonOffset = !isVertical ? 0.75 : multiple;
            const deltaX = line.endX - line.startX;
            const deltaY = line.endY - line.startY;
            const buttonLeft =
              line.startX + deltaX * buttonOffset - buttonSize / 2;
            const buttonTop =
              line.startY + deltaY * buttonOffset - buttonSize / 2;
            // Estilo do botão ---->>
            style_button = {
              position: "absolute",
              height: "30px",
              width: "30px",
              left: `${buttonLeft}px`,
              top: `${buttonTop}px`,
            };
            // Desenha as Linhas Retas ---->>

            // ctx.beginPath();
            // ctx.moveTo(line.startX, line.startY);
            // ctx.lineTo(line.endX, line.endY);
            // ctx.stroke();

            // Desenha as Linhas Pontilhadas ---->>
            // Check if this line is a dashed line
            const isDashed = count_parent_forEach.length === 1;

            if (isDashed) {
              // Define the dash pattern (alternating between segments and gaps)
              const segmentLength = 5; // Comprimento de cada segmento
              const gapLength = 10; // Comprimento do espaço vazio entre os segmentos
              ctx.setLineDash([segmentLength, gapLength]);

              // Desenha a linha pontilhada
              ctx.beginPath();
              ctx.moveTo(line.startX, line.startY);
              ctx.lineTo(line.endX, line.endY);
              ctx.stroke();

              // Reset the line dash to a solid line
              ctx.setLineDash([]);
            } else {
              // Desenha as Linhas ---->>
              ctx.beginPath();
              ctx.moveTo(line.startX, line.startY);
              ctx.lineTo(line.endX, line.endY);
              ctx.stroke();
            }
          }
          if (type === "button" && lineIndex === index) {
            style_button_return = style_button;
          }
        });
        if (type === "button") {
          return style_button_return;
        }
      }
      // ==========================================================================
      if (count_parent.length >= 2) {
        // Aqui faz o espaçamento entre as linhas do card pai -->>
        const horizontalSpacing = 15;
        const connectedLinesCount = this.lines.filter(
          (l) => l.card_parent === line.card_parent
        ).length;

        if (connectedLinesCount >= 2 && index > 0) {
          left += (index - 1) * horizontalSpacing;
        }
        // Define o style -->>
        const style = {
          width: `${width}px`,
          height: `${height}px`,
          left: `${left + 50}px`,
          top: `${top}px`,
          position: "absolute",
          border: "5px dashed #D9D9D9",
          borderTop: "none !important",
        };
        // Verificar a posição dos cards
        const isLeftCard = card1.x < card2.x;
        // const isRightCard = card1.x > card2.x;
        const isLeftCard2 = card1.y < card2.y;
        // const isRightCard2 = card1.y > card2.y;
        const isLeftBorderNone = isLeftCard;
        // const isRightBorderNone = isRightCard;
        const isLeftBorderNone2 = isLeftCard2;
        // const isRightBorderNone2 = isRightCard2;

        if (isLeftBorderNone && isLeftBorderNone2) {
          style.borderRight = "none !important";
          style.borderLeft = "5px dashed #D9D9D9 !important";
          style.borderTop = "none !important";
          style.transform = "rotate(0deg)";
        } else if (!isLeftBorderNone && isLeftBorderNone2) {
          style.borderRight = "5px dashed #D9D9D9 !important";
          style.borderLeft = "none !important";
          style.borderTop = "none !important";
          style.transform = "rotate(0deg)";
        } else if (isLeftBorderNone && isLeftBorderNone2 == false) {
          style.borderRight = "5px dashed #D9D9D9 !important";
          style.borderLeft = "none !important";
          style.borderTop = "none !important";
          style.transform = "rotate(180deg)";
        } else if (!isLeftBorderNone && !isLeftBorderNone2) {
          style.borderRight = "none !important";
          style.borderLeft = "5px dashed #D9D9D9 !important";
          style.borderTop = "none !important";
          style.transform = "rotate(180deg)";
        }

        if (type == "button") {
          const style_button = {
            position: "absolute",
            height: "30px",
            width: "30px",
          };

          if (isLeftBorderNone && isLeftBorderNone2) {
            style_button.left = "-15px";
            style_button.bottom = "-15px";
          } else if (!isLeftBorderNone && isLeftBorderNone2) {
            style_button.right = "-15px";
            style_button.bottom = "-15px";
          } else if (isLeftBorderNone && isLeftBorderNone2 == false) {
            style_button.right = "-15px";
            style_button.bottom = "-15px";
          } else if (!isLeftBorderNone && !isLeftBorderNone2) {
            style_button.bottom = "-15px";
            style_button.left = "-15px";
          }

          return style_button;
        }

        return style;
      }
    },
    // FUNÇÃO PARA REMOVER CARD --->>>
    removeCard(card, index) {
      // Validação para não ser possível excluir um e-mail caso ele seja o primeiro item da sequência e os e-mails seguintes
      // não forem configurados para serem enviados a todos os leads ou uma tag específica.
      let childrenCards = this.cards.filter(item => item.parent === card.id);
      if (card.parent === 0 && childrenCards.some(child => child.send_to !== "tag_specific" && child.send_to !== "all_leads")) {
        this.$bvToast.toast("Os e-mais seguintes não podem ser os itens iniciais da sequência", {
          title: "Erro ao deletar e-mail",
          variant: "danger",
          autoHideDelay: 8000,
          appendToast: true,
        });
        return
      }

      // Salva as linhas e cards para poder retornar ao valor original caso ocorra algum erro 
      let oldLines = [...this.lines];
      let oldCards = [...this.cards];
      Vue.swal({
        title: "E-mail",
        text: `Deseja realmente remover o e-mail da sequência?`,
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // Inicialmente é feita a alteração visual para garantir que a ação fique mais rápida para o usuário
          let parentCardId = card.parent;
          let parentCard = this.cards.find(item => item.id === parentCardId);

          // Troca a propiedade "card pai" dos cards filhos e cria as novas linhas (visualmente)
          for (let idx = 0; idx < childrenCards.length; idx++) {
            const child = childrenCards[idx];
            this.createLine(parentCard, child);
            let childIndex = this.cards.findIndex(item => item.id === child.id)
            if (childIndex) {
              this.cards[childIndex].parent = parentCardId
            }
          }

          // Cria um array decrescente com os indexes de todas as linhas que devem ser apagadas
          let oldLinesIndexes = []
          for (let index = 0; index < this.lines.length; index++) {
            const line = this.lines[index];
            if (line.card1.id === card.id || line.card2.id === card.id) {
              oldLinesIndexes.unshift(index);
            };
          }

          // Remove as linhas
          oldLinesIndexes.forEach(idx => this.lines.splice(idx, 1));
          const canvas = this.$refs.canvas;
          const ctx = canvas.getContext("2d");
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          // Remove o card visualmente
          this.cards.splice(index, 1);
          
          // Chama as requisições
          this.removeCardRequests(childrenCards, parentCardId, card, oldLines, oldCards)
        }
      });
    },
    async removeCardRequests(childrenCards, parentCardId, card, oldLines, oldCards) {
      let changedCards = []
      try {
        const promises = [];
        for (let idx = 0; idx < childrenCards.length; idx++) {
          const child = childrenCards[idx];
          const for_cards2 = {
            id: "/items/" + child.id,
            previous_item: parentCardId,
          };
          const promise = serviceFunnel.update(for_cards2).then(() => {
            changedCards.push(child.id)
          });
          promises.push(promise);
        }
        
        // Esperar todas as promessas terminarem
        await Promise.all(promises);

        var data = {
          id: `/items/${card.id}`,
        };
        await serviceFunnel.destroy(data);
      } catch (error) {
        // Avisa o usuário que ocorreu um erro
        this.$bvToast.toast("Erro ao deletar e-mail", {
          title: "Tente mais tarde ou chame o suporte",
          variant: "danger",
          autoHideDelay: 8000,
          appendToast: true,
        });

        // Renova as linhas e os cards visualmente
        this.cards = oldCards
        this.lines = oldLines.filter(line => line.card_parent !== card.id)
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        for (let index = 0; index < childrenCards.length; index++) {
          const childCard = childrenCards[index];
          this.createLine(card, childCard);
        }

        // Volta os cards alterados para seu valor inicial
        for (let index = 0; index < changedCards.length; index++) {
          const changedCard = changedCards[index];
          const for_cards2 = {
            id: "/items/" + changedCard,
            previous_item: card.id,
          };
          const promise = serviceFunnel.update(for_cards2)
        }
      }
    },
    reload(card, new_card) {
      this.createLine(card, new_card);
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      setTimeout(() => {
        this.skeleton_show = false;
      }, 650);
    },
    // FUNÇÃO PARA ADICIONAR NOVO CARD --->>>
    addNewCard(card, dadosEmailCreated) {
      card.dropDown = false;
      if (dadosEmailCreated.tag_dispatch) {
        this.qualification.tag_dispatch = dadosEmailCreated.tag_dispatch;
        // ajusta as automations
      }
      if (this.automations && this.automations[0] && this.automations[1]) {
        this.automations[0].email_id = dadosEmailCreated.id;
        this.automations[1].email_id = dadosEmailCreated.id;
      }
      var data = {
        id: "/items",
        funnel_id: this.funnel_id,
        previous_item: card.id,
        position: [
          {
            x: card.x + 350,
            y: card.y,
            offsetX: 0,
            offsetY: 0,
            isDragging: false,
            dropDown: false,
          },
        ],
        send_at: dadosEmailCreated.send_at,
        reference_type: "email",
        reference_id: dadosEmailCreated.id,
        send_to: dadosEmailCreated.destinatario.type,
        // qualificação -->
        send_filter: this.qualification,
        automations: this.automations,
      };
      var new_card;
      serviceFunnel
        .createId(data)
        .then((resp) => {
          this.skeleton_show = true;
          new_card = {
            id: resp.id,
            reference_id: resp.reference_id,
            reference_type: resp.reference_type,
            parent: resp.previous_item,
            funnel_id: this.funnel_id,
            send_to: resp.send_to,
            status: resp.status === "active" ? true : false,
            // POSITION -->>
            x: resp.position[0].x,
            y: resp.position[0].y,
            offsetX: resp.position[0].offsetX,
            offsetY: resp.position[0].offsetY,
            isDragging: resp.position[0].isDragging,
            dropDown: resp.position[0].dropDown,
            // QUALIFICATION -->>
            send_filter: resp.send_filter,
            // TIME -->>
            send_at: resp.send_at,
            automations: resp.automations,
            email_opens: 0,
            email_clicks: 0,
            email_delivereds: 0
          };
          var top = new_card.y;
          var left = new_card.x;
          this.skeleton_body = { x: left, y: top };
          this.$bvToast.toast("E-mail adicionado na sequencia!", {
            title: "Sequência",
            variant: "info",
            autoHideDelay: 8000,
            appendToast: true,
          });
          var att_email = {
            id: `email/update/${resp.reference_id}`,
            funnel_item_id: resp.id,
          };
          serviceFunnel.update(att_email);
          this.getEmails();
          // this.getEmails();
          // this.createLine(card, new_card);
          this.qualification = {};
          this.automations = [];
          this.cardSelected = [];
          // this.loadAllFunction();
          // this.lineStyle(new_card, this.cards.length);
        })
        .catch(() => {})
        .finally(() => {
          this.reload(card, new_card);
          this.cards.push(new_card);
        });
    },
    reloadLineButton(line, new_card) {
      var new_all_lines = [...this.lines];
      var index_line = new_all_lines.findIndex(
        (lineFind) => lineFind.id == line.id
      );
      if (index_line && index_line !== -1) {
        var card1 = new_all_lines[index_line].card1;
        var card2 = new_all_lines[index_line].card2;
        new_all_lines.splice(index_line, 1);
        this.lines = new_all_lines;
        this.createLine(card1, new_card);
        this.createLine(new_card, card2);
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        setTimeout(() => {
          this.skeleton_show = false;
        }, 650);
      } else {
        this.skeleton_show = false;
        this.loadAllFunction();
      }
    },
    // FUNÇÃO PARA ADICIONAR NOVO CARD ENTRE DOIS CARDS--->>>
    addNewCardInLineButton(line, dadosEmailCreated) {
      var new_line = line;
      var new_card;
      if (dadosEmailCreated.tag_dispatch) {
        this.qualification.tag_dispatch = dadosEmailCreated.tag_dispatch;
      }
      // ajusta as automations
      if (this.automations && this.automations[0] && this.automations[1]) {
        this.automations[0].email_id = dadosEmailCreated.id;
        this.automations[1].email_id = dadosEmailCreated.id;
      }
      var data = {
        id: "/items",
        funnel_id: this.funnel_id,
        previous_item: line.card1.id,
        position: [
          {
            x: (line.startX + line.endX) / 2 - 50,
            y: (line.startY + line.endY) / 2 - 50,
            offsetX: 0,
            offsetY: 0,
            isDragging: false,
            dropDown: false,
          },
        ],
        send_at: dadosEmailCreated.send_at,
        reference_type: "email",
        reference_id: dadosEmailCreated.id,
        send_to: dadosEmailCreated.destinatario.type,
        // qualificação -->
        send_filter: this.qualification,
        status: "inactive",
        automations: this.automations,
      };
      serviceFunnel
        .createId(data)
        .then((resp) => {
          new_card = {
            id: resp.id,
            reference_id: resp.reference_id,
            reference_type: resp.reference_type,
            parent: resp.previous_item,
            funnel_id: this.funnel_id,
            send_to: resp.send_to,
            status: resp.status === "active" ? true : false,
            // POSITION -->>
            x: resp.position[0].x,
            y: resp.position[0].y,
            offsetX: resp.position[0].offsetX,
            offsetY: resp.position[0].offsetY,
            isDragging: resp.position[0].isDragging,
            dropDown: resp.position[0].dropDown,
            // QUALIFICATION -->>
            send_filter: resp.send_filter,
            // TIME -->>
            send_at: resp.send_at,
            automations: resp.automations,
            email_opens: 0,
            email_clicks: 0,
            email_delivereds: 0
          };
          var card2 = {
            id: "/items/" + line.card2.id,
            previous_item: resp.id,
          };
          serviceFunnel.update(card2);
          this.$bvToast.toast("E-mail adicionado na sequencia!", {
            title: "Sequência",
            variant: "info",
            autoHideDelay: 8000,
            appendToast: true,
          });
          var att_email = {
            id: `email/update/${resp.reference_id}`,
            funnel_item_id: resp.id,
          };
          serviceFunnel.update(att_email);
          this.getEmails();
          this.qualification = {};
          this.automations = [];
          this.cardSelected = [];
          // this.loadAllFunction(); // NOVO JEITO DE INSERIR -->
          this.skeleton_show = true;
          var top = new_card.y;
          var left = new_card.x;
          this.skeleton_body = { x: left, y: top };
        })
        .catch(() => {})
        .finally(() => {
          this.reloadLineButton(new_line, new_card);
          this.cards.push(new_card);
        });
    },
    // FUNÇÃO PARA MOVER O CARD COM O MOUSE --->>>
    handleMouseMove(event) {
      if (this.isMouseDown) {
        this.mouseX = event.clientX;
        this.mouseY = event.clientY;
        this.updateCardPositions();
        this.updateNotePositions();
      }
    },
    // FUNÇÃO PARA MOVER O CARD COM O MOUSE --->>>
    handleMouseUp() {
      this.isMouseDown = false;
      this.cards.forEach((card) => {
        card.isDragging = false;
      });
    },
    // FUNÇÃO QUE CALCULA A POSIÇÃO DO CARD --->>>
    cardStyle(card) {
      return {
        top: `${card.y}px`,
        left: `${card.x}px`,
      };
    },
    // FUNÇÃO PARA MOVER O CARD COM O MOUSE --->>>
    startDragging(card, event, index, type) {
      if (!this.controlMoveCard) {
        return;
      }
      card.isDragging = true;
      this.isMouseDown = true;
      card.offsetX = event.clientX - card.x;
      card.offsetY = event.clientY - card.y;
      card.type = type;
      this.last_card = card;
    },
    eventSwitch(card, index) {
      card = this.cards[index];

      var data = {
        id: "/items/" + this.cards[index].id,
        status: card.status == true ? "active" : "inactive",
      };
      serviceFunnel.update(data);
    },
    handleDropCanvas(event) {
      if (
        !this.last_card ||
        this.last_card.isDragging == false ||
        event.target.classList.contains("notRequisition")
      ) {
        return;
      }

      if (this.last_card.id === 0) {
        var funnel_data = {
          id: this.$route.params.funnel_id,
          metas: {
            initial_x: this.last_card.x,
            initial_y: this.last_card.y,
          },
        };
        serviceFunnel.update(funnel_data);
        return;
      }

      var data = {
        id: "/items/" + this.last_card.id,
        position: [
          {
            x: this.last_card.x,
            y: this.last_card.y,
            offsetX: 0,
            offsetY: 0,
            isDragging: false,
            dropDown: false,
          },
        ],
      };

      if (this.last_card.type === "note") {
        data = {
          id: "/note/" + this.last_card.id,
          title: this.last_card.title,
          description: this.last_card.description,
          position: [
            {
              x: this.last_card.x,
              y: this.last_card.y,
              offsetX: 0,
              offsetY: 0,
              isDragging: false,
              dropDown: false,
            },
          ],
        };

        serviceCampaign.update(data);

        return;
      }

      if (this.last_card.type === "item") {
        serviceFunnel.update(data).then(() => {
          this.last_card = null;
        });
      }
    },
    handleDrop(card, event, index) {},
    // FUNÇÃO PARA MOVER O CARD COM O MOUSE --->>>
    updateCardPositions() {
      this.cards.forEach((card) => {
        if (card.isDragging) {
          card.x = this.mouseX - card.offsetX;
          card.y = this.mouseY - card.offsetY;

          this.lines.forEach((line) => {
            if (line.card1 === card) {
              line.startX = card.x + 50;
              line.startY = card.y + 50;
            }
            if (line.card2 === card) {
              line.endX = card.x + 50;
              line.endY = card.y + 50;
            }
          });
        }
      });
    },
    // FUNÇÃO QUE SELECIONA O CARD MOVIDO --->>>
    selectCard(card) {
      if (!this.selectedCard) {
        this.selectedCard = card;
      } else {
        this.createLine(this.selectedCard, card);
        this.selectedCard = null;
      }
    },
    // FUNÇÃO PARA CRIAR LINHAS ENTRE OS CARDS --->>>
    createLine(card1, card2) {
      const existingLine = this.lines.find(
        (line) => line.card1 === card1 && line.card2 === card2
      );
      if (existingLine) {
        return; // A linha já existe, não crie novamente
      }
      card2.parent = parseInt(card1.id);

      var id1 = JSON.stringify(card1.id);
      var id2 = JSON.stringify(card2.id);
      const line = {
        // PRECISA SABER QUAL CARD
        card1,
        card2,
        startX: card1.x + 50,
        startY: card1.y + 50,
        endX: card2.x + 50,
        endY: card2.y + 50,
        card_parent: card1.id,
        card_children: card2.id,
        id: id1 + id2,
      };

      this.lines.push(line);
    },
    // FUNÇÃO PARA CARREGAR POSIÇÃO DOS CARDS --->>>
    loadSavedPositions() {
      for (let i = 0; i < this.cards.length; i++) {
        const card = this.cards[i];
        const cardFilho = card.parent;

        if (cardFilho) {
          let result = this.cards.find(function (item) {
            return item.id == cardFilho;
          });
          this.createLine(result, card);
        }
      }
    },
    openModal(data, card, tipo) {
      setTimeout(() => {
        var email_id = card.reference_id;

        if (email_id) {
          let result = this.emails.find((item) => item.id == email_id);

          this.emailSelected = result;
        }

        this.cardSelected = card;

        if (this.cardSelected.id === 0) {
          this.destinatario = [
            {
              id: 1,
              title: "Enviar para todos os leads",
              type: "all_leads",
            },
            {
              id: 2,
              title: "Enviar para Tag especifica",
              type: "tag_specific",
            },
          ];
        } else {
          this.destinatario = [
            {
              id: 1,
              title: "Enviar para todos os leads",
              type: "all_leads",
            },
            {
              id: 2,
              title: "Abriram, mas não clicaram",
              type: "open_not_click",
            },
            { id: 3, title: "Nem abriram o e-mail", type: "not_open" },
            {
              id: 4,
              title: "Clicaram no objetivo",
              type: "click_objective",
            },
            {
              id: 5,
              title: "Enviar para Tag especifica",
              type: "tag_specific",
            },
          ];
        }
        if(tipo == 'create_email'){
          this.qualification = {}
        }
        this.type_api_email = tipo;
        this.$bvModal.show(data);
      }, 400);
    },
    fetchCampaing() {
      return new Promise((resolve, reject) => {
        serviceCampaign
          .read("/" + this.$route.params.campaign_id)
          .then(async (resp) => {
            this.campaign = resp;
            // this.funnel_id = resp.funnels[0].id;
            this.funnel_id = this.$route.params.funnel_id;
            resolve(true);
          })
          .catch(() => {
            reject(false);
          });
      });
    },

    debounceForms: _.debounce(function (query) {
      if (query === "") {
        this.loadingForm = false;
      } else {
        this.searchForm = query;
        this.fetchFunnelAll2(this.pageForm, query);
      }
    }, 500),
    fetchFunnelAll2(page = 1, query = "") {
      if (this.stopForm == true || this.loadingForm) {
        return;
      }

      this.loadingForm = true;

      var data = {
        page: page,
        campaign_id: this.$route.params.campaign_id,
        status: "active",
        order_by: "title",
        order: "ASC",
        search: query,
      };

      serviceForm
        .search(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page) {
            this.stopForm = true;
          }

          this.all_forms = this.all_forms.concat(resp.data);
          this.all_forms = this.all_forms.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    // fetchAllForms() {
    //   return new Promise((resolve, reject) => {
    //     var data = {
    //       campaign_id: this.$route.params.campaign_id,
    //       status: "active",
    //     };

    //     let currentPage = 1;
    //     let totalPages = 1;

    //     const fetchForms = () => {
    //       data.page = currentPage;

    //       serviceForm
    //         .search(data)
    //         .then(async (resp) => {
    //           // Verifica se o item já existe em this.all_forms antes de adicioná-lo
    //           resp.data.forEach((item) => {
    //             if (
    //               !this.all_forms.find(
    //                 (existingItem) => existingItem.id === item.id
    //               )
    //             ) {
    //               this.all_forms.push(item);
    //             }
    //           });

    //           // Atualiza o número total de páginas
    //           totalPages = resp.last_page || 1;

    //           // Se houver mais páginas, busca a próxima
    //           if (currentPage < totalPages) {
    //             currentPage++;
    //             fetchForms();
    //           } else {
    //             resolve(true); // Todas as páginas foram buscadas com sucesso
    //           }
    //         })
    //         .catch(() => {
    //           reject(false); // Ocorreu um erro na busca
    //         });
    //     };

    //     fetchForms();
    //   });
    // },
    fetchFunnel() {
      setTimeout(() => {
        serviceFunnel
          .read(`/${this.funnel_id}`)
          .then((resp) => {
            var formulario = this.all_forms.find(
              (form) => form.id == resp.form_id
            );

            this.selected_form = formulario;
            this.current_funnel = resp;
            var metas = resp.metas;
            this.zoom =
              metas.find((x) => x.meta_key === "zoom")?.meta_value ?? 10;
            this.scaleCanvas();

            var x = parseFloat(
              metas.find((x) => x.meta_key === "window_x")?.meta_value ?? 0
            );
            var y = parseFloat(
              metas.find((x) => x.meta_key === "window_y")?.meta_value ?? 0
            );

            const background = this.$refs.background;
            background.style.left = x >= 0 ? 0 : x + "px";
            background.style.top = y >= 0 ? 0 : y + "px";
          })
          .catch((err) => {
            // console.log(err);
          });
      }, 500);
    },
    fetchAllFunnels() {
      return new Promise((resolve, reject) => {
        var data = {
          campaign_id: this.$route.params.campaign_id,
        };

        let currentPage = 1;
        let totalPages = 1;

        const fetchFunnels = () => {
          data.page = currentPage;

          serviceFunnel
            .search(data)
            .then(async (resp) => {
              // Verifica se o item já existe em this.all_funnel antes de adicioná-lo
              resp.data.forEach((item) => {
                if (
                  !this.all_funnel.find(
                    (existingItem) => existingItem.id === item.id
                  )
                ) {
                  this.all_funnel.push(item);
                }
              });

              // Atualiza o número total de páginas
              totalPages = resp.last_page || 1;

              // Se houver mais páginas, busca a próxima
              if (currentPage < totalPages) {
                currentPage++;
                fetchFunnels();
              } else {
                resolve(true); // Todas as páginas foram buscadas com sucesso
              }
            })
            .catch(() => {
              reject(false); // Ocorreu um erro na busca
            });
        };

        fetchFunnels();
      });
    },
    // fetchCampaings() {
    //   return new Promise((resolve, reject) => {
    //     serviceCampaign
    //       .read("/list/all")
    //       .then(async (resp) => {
    //         this.campanhas = resp;
    //         resolve(true);
    //       })
    //       .catch(() => {
    //         reject(false);
    //       });
    //   });
    // },
    fetchItems() {
      return new Promise((resolve, reject) => {
        this.cards = [];
        this.lines = [];
        var data = {
          id: `/items/list/${this.funnel_id}`,
        };
        serviceFunnel
          .read(data)
          .then((resp) => {
            var card_var = [];
            for (let i = 0; i < resp.length; i++) {
              const card = resp[i];

              const data = {
                id: card.id,
                reference_id: card.reference_id,
                reference_type: card.reference_type,
                parent: card.previous_item,
                funnel_id: this.funnel_id,
                email_opens: card.email_opens_count,
                email_clicks: card.email_clicks_count,
                email_delivereds: card.email_delivereds_count,
                send_to: card.send_to,
                status: card.status === "active" ? true : false,
                // POSITION -->>
                x: card.position[0].x,
                y: card.position[0].y,
                offsetX: card.position[0].offsetX,
                offsetY: card.position[0].offsetY,
                isDragging: card.position[0].isDragging,
                dropDown: card.position[0].dropDown,
                // QUALIFICATION -->>
                send_filter: card.send_filter,
                // TIME -->>
                send_at: card.send_at,
                // FILTER LEADS EMAIL
                automations: card.automations,
              };
              card_var.push(data);
            }
            this.cards = card_var;

            var metas = this.current_funnel.metas;

            this.card_initial.x = parseFloat(
              metas.find((x) => x.meta_key === "initial_x")?.meta_value ?? 100
            );
            this.card_initial.y = parseFloat(
              metas.find((x) => x.meta_key === "initial_y")?.meta_value ?? 100
            );

            this.cards.unshift(this.card_initial);
            resolve(true);
          })
          .catch(() => {
            this.cards.unshift(this.card_initial);
            reject(false);
          });
      });
    },
    async loadAllFunction() {
      this.lines = [];
      this.loading = true;
      await this.fetchFunnelAll2();
      await this.fetchAllFunnels();
      await this.getNotes();
      await this.fetchCampaing();
      await this.fetchFunnel();
      try {
        await this.fetchItems();
      } catch (error) {
        console.error("Error in fetchItems:", error);
      }
      await this.getEmails();
      await this.loadSavedPositions();
      await this.getGraphEmail();
      // await this.fetchCampaings();
      this.loading = false;
    },
  },
  async mounted() {
    document.body.style.userSelect = "none";
    const background = this.$refs.background;
    let isDragging = false;
    let startPosX = 0;
    let startPosY = 0;
    let startLeft = 0;
    let startTop = 0;

    await this.loadAllFunction();

    background.addEventListener("mousedown", (event) => {
      if (
        event.target.classList.contains("validDrag") ||
        event.target.classList.contains("container-loading")
      ) {
        return;
      }

      isDragging = true;
      startPosX = event.clientX;
      startPosY = event.clientY;
      startLeft = background.offsetLeft;
      startTop = background.offsetTop;
    });

    document.addEventListener("mousemove", (event) => {
      if (event.target.classList.contains("validDrag")) {
        return;
      }

      var divCanvas = document.querySelector("#canvas");
      if (isDragging) {
        divCanvas.style.cursor = "-webkit-grabbing";
        const deltaX = event.clientX - startPosX;
        const deltaY = event.clientY - startPosY;
        const newLeft = startLeft + deltaX;
        const newTop = startTop + deltaY;

        // Verificar limites x mínimos
        if (newLeft >= 0) {
          background.style.left = "0px";
        } else {
          background.style.left = newLeft + "px";
        }

        // Verificar limites y mínimos
        if (newTop >= 0) {
          background.style.top = "0px";
        } else {
          background.style.top = newTop + "px";
        }

        this.debouncePosition({ x: newLeft, y: newTop });
      }
    });

    document.addEventListener("mouseup", () => {
      var divCanvas = document.querySelector("#canvas");
      if (divCanvas) {
        divCanvas.style.cursor = "-webkit-grab";
        isDragging = false;
      }
    });

    window.addEventListener("mousemove", this.handleMouseMove);
    window.addEventListener("mouseup", this.handleMouseUp);
    window.addEventListener("mouseup", this.handleMouseUp2);

    // Carrega as posições salvas ao iniciar
    // this.loadSavedPositions();
    var body = document.body;
    body.classList.add("notification-campaign");
  },
  beforeDestroy() {
    document.body.style.userSelect = "";
    window.removeEventListener("mousemove", this.handleMouseMove);
    window.removeEventListener("mouseup", this.handleMouseUp);
    window.removeEventListener("mouseup", this.handleMouseUp2);
    var body = document.body;
    body.classList.remove("notification-campaign");
  },
};
</script>

<style lang="scss">
.faketextarea {
  height: 65px;
  width: 220px;
  overflow: auto;
}
.container-skeleton {
  position: absolute;
  height: 265px;
  width: 300px;
  z-index: 9;
  background: #f7f7f7;
  border-radius: 10px;
}
.open-not-click {
  filter: brightness(1.21) contrast(1.53) hue-rotate(-97deg);
}
.not-open {
  filter: brightness(1.13) hue-rotate(-143deg) saturate(2.59);
}
.click-objective {
  filter: hue-rotate(85deg) saturate(1.9);
}
// @import "../../assets/scss2/main.scss";
// #renderLine {
//   width: 100%;
//   height: 100%;
// }
.card-formulario {
  span.multiselect__single {
    color: #000 !important;
    font-weight: 600;
  }
  .multiselect__option {
    span {
      font-weight: 400 !important;
      font-size: 14px !important;
      color: #000 !important;
    }
  }
  .multiselect__option--highlight {
    // background: #020016 !important;
    span {
      font-weight: 400 !important;
      font-size: 14px !important;
      color: #000 !important;
    }
  }
}
.container-button {
  position: absolute;
  top: 105px;
  left: 299px;
  user-select: none;
  button {
    -webkit-box-shadow: 3px 1.5px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 3px 1.5px 7px rgba(0, 0, 0, 0.3);
    background: #ffffff;
    border: 2px solid #d9d9d9;
    border-radius: 10px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-left: none !important;
    width: 23px;
    border: none;
    padding: 2px;
    margin-top: 8px;
    border-radius: 0px 4px 4px 0px;
    user-select: none;
    &:hover {
      background: #fafafa;
      transform: scale(1.1);
    }
  }
}

.container-sequence {
  img {
    display: inline !important;
  }
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  svg {
    overflow: hidden;
    vertical-align: middle;
  }
  #note-edit-title {
    width: 80%;
    padding: 15px 8px !important;
    height: 40px !important;
    border: 0.5px solid #ededf0 !important;
    outline: none !important;
    appearance: none !important;
    font-size: 14px !important;
    transition: 0.3s !important;
    border-radius: 10px !important;
    margin-bottom: 12px !important;
    &:hover {
      border: 0.5px solid #00a650 !important;
    }
  }

  .title-note {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    cursor: default !important;
    margin-bottom: 15px !important;
    label {
      cursor: pointer !important;
      font-weight: 600 !important;
      transition: all 0.3s !important;
      width: 190px !important;
      margin-bottom: 0px !important;
      overflow: hidden !important;
      white-space: nowrap !important;
      text-overflow: ellipsis !important;
      font-size: 14px !important;
      &:hover {
        color: #a70f33 !important;
      }
    }
    img {
      cursor: pointer !important;
      right: 5px !important;
      position: relative !important;
    }
  }
  .text-area {
    line-height: normal !important;
    height: 65px !important;
    resize: none !important;
    padding: 8px !important;
    width: 100% !important;
    font-size: 12px !important;
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: var(--gray01) !important;
    background: #fff6 !important;
    border: 0.5px solid var(--white-dark) !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
  }

  .text-area::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 20px;
    transition: all 0.5s;
  }
  .text-area::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .text-area::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }
  .editabbleTextArea {
    border: 0.5px solid #00a650 !important;
  }
  .disabledTextArea {
    transition: all 0.3s;
    &:hover {
      cursor: pointer !important;
      border: 0.5px solid #a70f33 !important;
    }
  }

  .dropDownCard {
    position: absolute;
    background: #fff;
    padding: 10px;
    z-index: 9;
    border-radius: 10px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
    right: -145px;
    top: 25px;
    display: grid;
    gap: 5px;
    .actions {
      display: flex;
      align-items: center;
      gap: 10px;
      transition: all 0.2s;
      padding: 10px;
      p {
        margin: 0;
        font-weight: 600;
        font-size: 14px;
      }
      &:hover {
        border-radius: 10px;
        background: #f5f7fb;
      }
    }
    .trash:hover {
      background: #f10d431a;
    }
  }

  .validDrag {
    user-select: none !important;
  }

  .addCardButton {
    img {
      user-select: none !important;
      pointer-events: none !important;
    }
  }

  .initial-card {
    position: absolute;
    top: -25px;
    left: 0;
    h1 {
      background: rgba(0, 166, 80, 0.1);
      border-radius: 10px;
      color: #00a650;
      font-size: 12px;
      font-weight: 600;
      padding: 5px;
    }
  }
  .content-card {
    display: grid;
    padding-top: 10px;
    gap: 10px;
    .title {
      display: flex;
      align-items: center;
      gap: 15px;
      h2 {
        font-size: 15px;
        color: #000000;
        font-weight: 600;
        margin: 0 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 220px;
        line-height: 20px;
      }
    }
    p {
      font-size: 14px;
      margin: 0 !important;
    }
    span {
      font-size: 14px;
      color: #9a9a9a;
    }
  }
  .header-card {
    display: flex;
    align-items: center;
    gap: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e5e5e5;
    .text {
      display: flex;
      align-items: center;
      h1 {
        font-size: 16px;
        color: #000000;
        font-weight: 600;
        margin: 0 !important;
        word-break: break-word;
      }
      #image {
        position: absolute;
        right: 20px;
        padding: 10px;
      }
    }
  }
  .addCardButton {
    background: #ffffff;
    border: 2px solid #d9d9d9;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  .line {
    animation: animacaoBorda 0.3s ease-out;
  }

  @keyframes animacaoBorda {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 25%;
    }
    50% {
      opacity: 50%;
    }
    75% {
      opacity: 75%;
    }
    100% {
      opacity: 100%;
    }
  }
  .lines-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .add-card-sequence {
    position: relative;
    img {
      cursor: pointer;
      margin: 0 auto;
      border-radius: 50%;
      padding: 5px;
      border: 1px solid #d9d9d9;
      pointer-events: none !important;
    }
  }

  .card {
    width: 300px;
    position: absolute;
    user-select: none;
    background: #ffffff;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
    padding: 20px;
    z-index: 1;
    &:has(.dropDownCard:not([style*="display: none"])) {
      z-index: 2;
    }
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
  }
  .content-zoom {
    position: absolute;
    z-index: 11;
    top: 200px;
    right: 15px;
    display: grid;
    gap: 20px;
    .zoom {
      background: #fff;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
    .zoom2 {
      position: absolute;
      color: var(--gray04);
      bottom: 110px;
      left: 6px;
    }
  }

  div#canvas {
    background: #fcfcfc;
    background-image: url("https://testespark.gpages.com.br/gadmin/construtor/bg.svg");
    width: 15000px;
    height: 5000px;
    cursor: -webkit-grab;
    transform: matrix(1, 0, 0, 1, 0, 0);
    overflow: hidden;
    margin-top: 86px;
    position: absolute;
    top: 0;
    left: 0;
  }
  // LOADING -->
  .container-loading {
    background: #fcfcfc;
    background-image: url("https://testespark.gpages.com.br/gadmin/construtor/bg.svg");
    width: 100vw;
    height: 100vh;
    // top: 86px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lds-ellipsis {
    display: flex;
    position: relative;
    right: 40px;
    // width: 200px;
    // height: 200px;
  }
  .lds-ellipsis div {
    position: absolute;
    // top: 33px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--green);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
}

</style>
